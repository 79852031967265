import { useMemo, lazy, Suspense } from 'react';
import Loading from '@/components/shared/Loading';
import { LAYOUT_TYPE_MODERN } from '@/constants/theme.constant';
import useDirection from '@/utils/hooks/useDirection';
import useLocale from '@/utils/hooks/useLocale';
import { useAuth0 } from '@auth0/auth0-react';
import useInitializeHotjar from '@/utils/hooks/useInitializeHotjar';

const layouts = {
    [LAYOUT_TYPE_MODERN]: lazy(() => import('./ModernLayout')),
};

const Layout = () => {
    const { isAuthenticated } = useAuth0();

    useDirection();
    useLocale();
    useInitializeHotjar();

    const AppLayout = useMemo(() => {
        if (isAuthenticated) {
            return layouts['modern'];
        }
        return lazy(() => import('./AuthLayout'));
    }, [isAuthenticated]);

    return (
        <Suspense
            fallback={
                <div className="flex flex-auto flex-col h-[100vh]">
                    <Loading loading={true} />
                </div>
            }
        >
            <AppLayout />
        </Suspense>
    );
};

export default Layout;

