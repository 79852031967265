import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from './store';
import Theme from '@/components/template/Theme';
import Layout from '@/components/layouts';
import QueryClientWrapper from '@/providers/QueryClientWrapper';
import Auth0Wrapper from '@/providers/Auth0Wrapper';
import './locales';

function App() {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <BrowserRouter>
                    <QueryClientWrapper>
                        <Auth0Wrapper>
                            <Theme>
                                <Layout />
                            </Theme>
                        </Auth0Wrapper>
                    </QueryClientWrapper>
                </BrowserRouter>
            </PersistGate>
        </Provider>
    );
}

export default App;

