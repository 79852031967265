/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import axios, { AxiosError } from 'axios';
import appConfig from '@/configs/app.config';
import { TOKEN_TYPE, REQUEST_HEADER_AUTH_KEY } from '@/constants/api.constant';
import { auth0Logout, getAccessToken } from '@/utils/auth0Utils';

const unauthorizedCode = [401, 403];

const BaseService = axios.create({
    timeout: 60000,
    baseURL: import.meta.env.VITE_BACKEND_BASE_URL + appConfig.apiPrefix,
});

BaseService.interceptors.request.use(
    async (config) => {
        const accessToken = await getAccessToken();
        if (accessToken) {
            config.headers[REQUEST_HEADER_AUTH_KEY] =
                `${TOKEN_TYPE}${accessToken}`;
        } else {
            await auth0Logout();
        }
        return config;
    },
    async (error) => {
        await auth0Logout();
        return Promise.reject(new Error(error));
    },
);

let retryCount = 0;

BaseService.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error: AxiosError) => {
        const { response, config } = error;
        if (
            !config ||
            !response ||
            retryCount >= 2 ||
            unauthorizedCode.includes(response.status)
        ) {
            await auth0Logout();
            return Promise.reject(error);
        }

        if (response.status === 401 && retryCount < 2) {
            retryCount = retryCount + 1;
            try {
                const newAccessToken = await getAccessToken();
                if (newAccessToken) {
                    config.headers[REQUEST_HEADER_AUTH_KEY] =
                        `${TOKEN_TYPE} ${newAccessToken}`;
                    return axios(config);
                }
            } catch (tokenError) {
                await auth0Logout();
                return Promise.reject(new Error('User error'));
            }
        }

        return Promise.reject(error);
    },
);

export default BaseService;

