import { useEffect } from 'react';
import useHotjar from 'react-use-hotjar';

const useInitializeHotjar = () => {
    const { initHotjar } = useHotjar();

    useEffect(() => {
        const hotjarSiteId = Number(import.meta.env.VITE_PUBLIC_HOTJAR_SITE_ID);
        const hotjarVersion = Number(
            import.meta.env.VITE_PUBLIC_HOTJAR_VERSION,
        );

        if (hotjarSiteId && hotjarVersion) {
            initHotjar(hotjarSiteId, hotjarVersion, false);
        }
    }, [initHotjar]);
};

export default useInitializeHotjar;

